/* purgecss start ignore */

.Form_container__gA321 {
  white-space: pre-line;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-bottom: 1.25rem;
}

@media (min-width: 992px) {
  .Form_container__gA321 {
    padding-bottom: 1.75rem;
  }
}

.Form_container__gA321.Form_darkTheme__A15yv {
  background-color: var(--primaryA);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--primaryB);
}

@media (min-width: 992px) {
  .Form_container__gA321.Form_darkTheme__A15yv {
    padding-bottom: 0.625rem;
  }
}

.Form_container__gA321.Form_darkTheme__A15yv {
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.2);
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}

.Form_darkTheme__A15yv {
  background-color: var(--primaryA);
  color: var(--primaryB);
}

.Form_title__uJ_Gm {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  text-align: center;
  font-family: Gilda Display, serif;
  font-size: 2.125rem;
  line-height: 2.625rem;
  font-weight: 325;
}

.Form_title__uJ_Gm.Form_darkTheme__A15yv {
  line-height: 2rem;
  font-size: 1.5rem
}

@media (min-width: 992px) {

.Form_title__uJ_Gm.Form_darkTheme__A15yv {
    text-align: left
}
  }

.Form_desc__J1S8r {
  text-align: center;
  font-family: Lora, serif;
  font-size: .875rem;
  line-height: 1.125rem;
  line-height: 1.375rem;
}

@media (min-width: 992px) {

.Form_desc__J1S8r.Form_darkTheme__A15yv {
    text-align: left
}
  }

.Form_miniDesc__VqTI4 {
  text-align: center;
  font-family: Lora, serif;
  font-size: .6875rem;
  line-height: 1.125rem;
  margin-top: 0.625rem;
}

@media (min-width: 992px) {

.Form_miniDesc__VqTI4.Form_darkTheme__A15yv {
    text-align: left
}
  }

.Form_smiley__VvLHL {
  font-style: normal;
}

.Form_italicText___pDWm {
  font-style: italic;
}

.Form_form__dltZ9 {
  margin-top: 1.25rem;
}

.Form_select__1mvQT {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.Form_inlineInputs__3eTkd {
  display: flex;
  width: 100%;
  flex-direction: column
}

@media (min-width: 992px) {

.Form_inlineInputs__3eTkd {
    flex-direction: row;

    align-items: flex-start
}
  }

@media (min-width: 992px) {

.Form_inlineInputs__3eTkd > :first-child {
    width: 100%;

    margin-right: 0.75rem
}
  }

@media (min-width: 992px) {

.Form_inlineInputs__3eTkd > :last-child {
    width: 100%;

    margin-left: 0.75rem
}
  }

.Form_radioInput__kyCOZ > button {
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
}

.Form_labelField__CSl_k {
  margin-bottom: 0.25rem;
  display: block;
  font-size: 0.875rem
}

@media (min-width: 992px) {

.Form_labelField__CSl_k {
    font-size: 0.6875rem;

    margin-bottom: 0px;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem
}
  }

.Form_field__Qkq8U {
  margin-left: 0.25rem;
  height: 3rem;
  width: 100%;
  border-width: 1px;
  border-color: var(--grey300)
}

@media (min-width: 992px) {

.Form_field__Qkq8U {
    grid-column: span 2 / span 2;

    margin-left: 0px;

    height: 2.5rem
}
  }

[dir='rtl'] .Form_field__Qkq8U {
  margin-right: 0.25rem;
  margin-left: 0px
}

@media (min-width: 992px) {

[dir='rtl'] .Form_field__Qkq8U {
    margin-right: 0px
}
  }

.Form_errorInput__1ByP0 {
  border-width: 1px;
  border-style: solid;
  border-color: var(--negative);
}

.Form_titleField__Kql2x {
  width: 7rem;
  background-color: transparent;
}

.Form_selectField__8BZSO {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.Form_btnPosition__8gjIX {
  margin-top: 1.5rem;
  display: inline-block;
  width: 100%
}

@media (min-width: 992px) {

.Form_btnPosition__8gjIX {
    margin-top: 0.625rem
}
  }

/* mt-0 et w-auto pour le bouton de la page de confirmation en dark theme */

@media (min-width: 992px) {

.Form_inlineButton__sJCVa {
    margin-top: 0px;

    width: auto
}
  }

.Form_btn__egiDN {
  width: 100%;
}

.Form_btn__egiDN.Form_darkTheme__A15yv {
  background-color: var(--primaryB);
  color: var(--primaryA);
}

.Form_btn__egiDN:hover.Form_darkTheme__A15yv {
  border-color: var(--primaryB);
  background-color: var(--primaryA);
  color: var(--primaryB);
}

@media (min-width: 992px) {

.Form_submissionLine__rVBSQ {
    position: relative;

    display: flex;

    align-items: center
}
  }

@media (min-width: 992px) {

.Form_submissionLine__rVBSQ > :first-child {
    width: 85%
}
  }

.Form_alertContainer__iwkLI {
  margin-bottom: 1rem;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Success_container__Co4c8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31.25rem;
  padding: 2.1875rem 2.5rem 5.75rem 2.5rem;
}

.Success_container__Co4c8.Success_darkTheme__xHHK3 {
  align-items: flex-start;
  background-color: var(--primaryA);
  text-align: left;
  color: var(--primaryB);
  padding: 6.75rem 1.5rem;
}

.Success_container__Co4c8.Success_darkTheme__xHHK3.Success_fullHeight__r7Bir {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.Success_title__35e19 {
  margin-bottom: 0.375rem;
  text-align: center;
  font-family: Gilda Display, serif;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 400;
  line-height: 2rem;
  font-weight: 325;
}

.Success_desc__e7wi1 {
  text-align: center;
  font-family: Lora, serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.Success_container__Co4c8.Success_darkTheme__xHHK3 .Success_desc__e7wi1 {
  text-align: center
}

@media (min-width: 992px) {

.Success_container__Co4c8.Success_darkTheme__xHHK3 .Success_desc__e7wi1 {
    text-align: left
}
  }

.Success_btn__0EfI4 {
  margin-top: 2.5rem;
  width: 45%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.Error_container__xJdex {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31.25rem;
  padding: 2.1875rem 2.5rem 5.75rem 2.5rem;
}

.Error_container__xJdex.Error_darkTheme__FWOIN {
  align-items: flex-start;
  background-color: var(--primaryA);
  text-align: left;
  color: var(--primaryB);
  padding: 6.75rem 1.5rem;
}

.Error_container__xJdex.Error_darkTheme__FWOIN.Error_fullHeight__WvS_J {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.Error_title__YAQ0K {
  margin-bottom: 0.375rem;
  text-align: center;
  font-family: Gilda Display, serif;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 400;
  line-height: 2rem;
  font-weight: 325;
}

.Error_desc__FBFX3 {
  text-align: center;
  font-family: Lora, serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.Error_container__xJdex.Error_darkTheme__FWOIN .Error_desc__FBFX3 {
  text-align: center
}

@media (min-width: 992px) {

.Error_container__xJdex.Error_darkTheme__FWOIN .Error_desc__FBFX3 {
    text-align: left
}
  }

.Error_btn__w56Gw {
  margin-top: 2.5rem;
  width: 45%;
}

/* purgecss end ignore */

/* purgecss start ignore */

.AlreadySubscribed_container__d_Rg5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 31.25rem;
  padding: 2.1875rem 2.5rem 5.75rem 2.5rem;
}

.AlreadySubscribed_container__d_Rg5.AlreadySubscribed_darkTheme__J9szw {
  align-items: flex-start;
  background-color: var(--primaryA);
  text-align: left;
  color: var(--primaryB);
  padding: 6.75rem 1.5rem;
}

.AlreadySubscribed_container__d_Rg5.AlreadySubscribed_darkTheme__J9szw.AlreadySubscribed_fullHeight__2Oedz {
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.AlreadySubscribed_title__sbMpO {
  margin-bottom: 0.375rem;
  text-align: center;
  font-family: Gilda Display, serif;
  font-size: 1.5rem;
  line-height: 1.1;
  font-weight: 400;
  line-height: 2rem;
  font-weight: 325;
}

.AlreadySubscribed_desc__1lyem {
  text-align: center;
  font-family: Lora, serif;
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 400;
  line-height: 1.375rem;
}

.AlreadySubscribed_container__d_Rg5.AlreadySubscribed_darkTheme__J9szw .AlreadySubscribed_desc__1lyem {
  text-align: center
}

@media (min-width: 992px) {

.AlreadySubscribed_container__d_Rg5.AlreadySubscribed_darkTheme__J9szw .AlreadySubscribed_desc__1lyem {
    text-align: left
}
  }

.AlreadySubscribed_btn__k0X7x {
  margin-top: 2.5rem;
  width: 45%;
}

/* purgecss end ignore */

/* purgecss start ignore */

@keyframes NewsletterModal_slideIn__ENgMU {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes NewsletterModal_slideOut__rjaUq {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-120%);
  }
}

.NewsletterModal_container__KEeIJ {
  z-index: 40
}

@media (min-width: 992px) {

.NewsletterModal_container__KEeIJ {
    flex: 1 1;

    display: flex
}
  }

.NewsletterModal_container__KEeIJ.NewsletterModal_autoDisplay__2JFj8 {
  position: fixed;
  bottom: 0px;
  animation: NewsletterModal_slideIn__ENgMU 0.8s forwards;
}

.NewsletterModal_container__KEeIJ.NewsletterModal_autoDisplay__2JFj8.NewsletterModal_slideOut__rjaUq {
  position: fixed;
  bottom: 0px;
  animation: NewsletterModal_slideOut__rjaUq 0.8s forwards;
}

.NewsletterModal_container__KEeIJ.NewsletterModal_mobile__GOlzI {
  bottom: 1.25rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.NewsletterModal_container__KEeIJ.NewsletterModal_mobile__GOlzI.NewsletterModal_slideOut__rjaUq {
  bottom: 1.25rem;
}

.NewsletterModal_container__KEeIJ.NewsletterModal_mobile__GOlzI.NewsletterModal_open__1id_q {
  top: 5rem;
  overflow-y: auto;
}

@media (min-width: 992px) {

.NewsletterModal_modal__U7bIa {
    flex: 9 1;

    max-width: 31.25rem
}
  }

.NewsletterModal_modal__U7bIa.NewsletterModal_fullHeight__Yj_lw {
  height: 100%;
}

.NewsletterModal_close__1ZH_8 {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  color: var(--primaryB);
}

.NewsletterModal_container__KEeIJ.NewsletterModal_full__KCo8N {
  width: 100%;
}

@media (min-width: 992px) {
  .NewsletterModal_container__KEeIJ.NewsletterModal_full__KCo8N {
    position: relative;
    display: flex;
    justify-content: center;
    background-image: none;
  }

  .NewsletterModal_container__KEeIJ.NewsletterModal_full__KCo8N .NewsletterModal_emptyDiv__S3ZD4 {
    position: absolute;
    left: 0px;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: url(/img/fond-pop-in.png) white 0 -2rem no-repeat;
  }

  .NewsletterModal_container__KEeIJ.NewsletterModal_full__KCo8N .NewsletterModal_modal__U7bIa {
    z-index: 10;
    width: 100%;
    max-width: 37.5rem;
  }
}

/* purgecss end ignore */

